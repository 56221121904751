.App {
  font-family: "Livvic",sans-serif;
  text-align: center;

  button {
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
  }
}
