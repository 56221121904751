.SiteMenuHeader {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    background-color: #eee;

    .menu-section {
        ul {
            margin: 0;
            padding: 15px 0 20px 0;
    
            :not(:last-child) { 
                border-right: 1px solid #eee;
            }

            li {
                display: inline;
                padding: 0 20px 0 15px;
                
                a {
                    text-decoration: none;
                }
            }
        }
    }

    .social-section {
        .social-icons {
            :not(:last-child) {
                border-right: 1px solid #bbb;
            }

            .social-icon {
                padding: 5px 15px 5px 15px;
            }
        }
    }
}