.SiteHeader {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    // height: 45px;
    // padding: 25px 0 15px 0;
    border-bottom: 1px solid #e1e1e1;

    .log-container {
        display: flex;
        align-items: center;
        min-height: 50px;
        color: #212529;
        font-weight: bold;
        font-size: 24px;

        .logo {
            padding: 0 20px 0 0;
        }
    }

    .contact-container {
        .IconText {
            .icon {
                color: #106eea;
            }        
        }    
    }
}