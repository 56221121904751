.IconText {
    display: flex;
    align-items: center;
    text-align: left;
    padding: 5px 30px 5px 30px;

    .icon {
        padding: 0 10px 0 0;
    }

    .text {
        display: flex;
        flex-direction: column;
        color: #0a0a0a;

        .header {
            font-size: 15px;
            color: #76767d;
            padding: 0 0 5px 0;
        }

    }
}